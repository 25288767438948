import React from 'react';
import { Link } from 'gatsby';
import Layout from "../../../components/layout"
import { Button } from '../../../components/globalComponents';
import SEO from "../../../components/seo"
import { MessageSquareCheck } from '@styled-icons/boxicons-solid/MessageSquareCheck';

import { SuccessContentSafeArea } from './styles';

export default function Sucesso(){
  return(
    <Layout>
      <SEO title="Sucesso!" />
      <SuccessContentSafeArea>
        <MessageSquareCheck size={100} color={'#18C33B'} />
        <h1>Mensagem enviada com sucesso!</h1>
        <h2>Retornaremos o mais rápido possível.</h2>
        <Button as={Link} to='/'> Retornar ao site </Button>
      </SuccessContentSafeArea>
    </Layout>
  )
}
