import styled from 'styled-components';
import { ContentSafeArea } from '../../../components/globalComponents';

export const SuccessContentSafeArea = styled(ContentSafeArea)`
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    margin-bottom: 2rem;
  }

  h2 {
    font-weight: 400;
  }
`
